import React from "react"
import {Layout } from '../components/layout'
import { HomeBar } from "../components/Navbar/HomeBar"
import { ReturnBar } from "../components/Navbar/ReturnBar"

const NotFoundPage = () => {
  return(
    <Layout title={"Austin Loza - Page Not Found"}>
      <HomeBar />
      <div className="px-10 mb-14 font-bold">
        <h1 className="text-9xl">404</h1>
        <h2 className="text-7xl">Page<br/> Not<br/> Found</h2>
      </div>
      
      <div className="flex justify-center mb-24">
        <p className="text-xl">
          I'm sorry, the page you're looking for does not exist. 
          <br/>
          It may be under construction, maintenance,
          or was never here to begin with.
        </p>
      </div>

      <div className="min-w-full flex justify-evenly">
        <button className="transition ease-linear duration-300 bg-gray-400 text-black hover:text-white hover:bg-black p-5 rounded-md" onClick={e => {window.location.href = "/"}}>Return to home</button>
      </div>
    </Layout>
  )
}

export default NotFoundPage
