import React from 'react'
import { Navbar } from './navbar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

export function HomeBar(){
    return(
        <Navbar className="text-right">
            <a href='/' className='w-full transform ease-in hover:text-yellow-400'>
                <FontAwesomeIcon className="text-3xl" icon={faHome} />
            </a>
        </Navbar>
    );
}